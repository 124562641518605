import { useLocation } from "@reach/router"
import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import { Element as ScrollElement, Link as ScrollLink } from "react-scroll"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import SVGIcon from "../components/common/SVGIcon"
import Buttons from "../components/common/button"
import FooterLP from "../components/common/footer-lp"
import Frame from "../components/common/frame"
import "../styles/pages/theprofit.scss"
import Popper from "./../assets/images/party-popper.svg"

const ProfitPlaybook = () => {
  const data = ProfitPlaybookPageQuery()
  const [success, setSuccess] = useState(false)
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])
  const { pageComponents, links } = data
  const { newfield, seo, hero, card, images } = pageComponents[0]

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter a company name",
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
  ]

  const currentLocation = useLocation()
  const path = currentLocation.href

  const handleAfterSubmit = (res, data) => {
    if (res?.status === 200) setSuccess(true)
  }

  const FormWrapper = () => {
    return (
      <div id="mob_form" className="form-wrapper down-to-up-5">
        {!success ? (
          <FormBuilder
            formClassName="forms"
            errorClassName="error-message"
            inputErrorClassName="error-active"
            data={formdata}
            extradata={{
              name: "outbound_source",
              value: "Webinar - Profit Series - May 24",
            }}
            buttonText={hero.primaryButtonText}
            buttonClassName="primary pink"
            url={path}
            endpoint={process.env.HUBSPOT_WEBINAR_REGISTER_API_ENDPOINT}
            afterSubmit={handleAfterSubmit}
            IpStackData
            formName={`The Profit Playbook - Superops`}
          />
        ) : (
          <div className="form-success">
            <div className="text-center mb24">
              <img src={Popper} alt="popper" className="mb24 popper-img" />

              <h3 className="mb24 fw-bold font-white font-roboto">
                Thanks for registering!
              </h3>
              <p className="p20 font-white">You'll receive the details in your inbox.</p>
            </div>
          </div>
        )}
      </div>
    )
  }

  const highlightForm = () => {
    const mobForm = document.getElementById("mob_form")
    if (mobForm) {
      if (screenWidth >= 992) {
        mobForm.classList.remove("down-to-up-5")
        mobForm.classList.add("highlight")
        setTimeout(() => {
          mobForm.classList.remove("highlight")
        }, 700)
      } else {
        mobForm.scrollIntoView({ block: "center", behavior: "smooth" })
      }
    }
  }

  return (
    <div className="profit-page">
      <Frame
        theme="dark"
        footerBgColor="#0E0E0E"
        seo={seo}
        bodyClassName="bg-black p-0"
        IsFooterVisble="No"
      >
        <section className="hero-section prelative">
          <div className="hero-gradient position-absolute w100 h100"></div>
          <Container className="Layout-container">
            <div className="d-flex">
              <Col lg="6" className="">
                <div className="sops-logo down-to-up-1">
                  <Link to="/">
                    <SVGIcon name="spsLogoLightNew" className="w-100" />
                  </Link>
                </div>
                <div className="down-to-up-2 profitImg">
                  <img src={hero.heroImage?.url} className="w-100" />
                </div>
                <h2 className="title fw-800 down-to-up-3 mb24 font-roboto">
                  {hero.heading?.text}
                </h2>

                <p className="description down-to-up-4">{hero.content?.text}</p>
                <div className="d-mob hero-btn down-to-up-5">
                  <ScrollLink
                    to="form"
                    spy={true}
                    smooth={"easeInOutCubic"}
                    hashSpy={true}
                    offset={-100}
                    duration={300}
                    delay={0}
                    isDynamic={true}
                  >
                    <div className="d-flex justify-content-center">
                      <Buttons
                        theme="primary pink lg w-100"
                        text={hero.primaryButtonText}
                      />
                    </div>
                  </ScrollLink>
                </div>
              </Col>
            </div>
          </Container>
        </section>

        <div>
          {screenWidth >= 992 ? (
            <div
              className="position-sticky d-dsk"
              style={{ top: "300px", zIndex: "2" }}
            >
              <div className="Layout-container mx-auto position-relative d-flex justify-content-end">
                <Col
                  lg={6}
                  className="d-flex justify-content-end position-absolute"
                  style={{ top: "-200px" }}
                >
                  <FormWrapper />
                </Col>
              </div>
            </div>
          ) : null}

          <section className="mt50 mb100 detail-section">
            <Container className="Layout-container">
              <Col lg={6}>
                <Fade bottom duration={500}>
                  <p className="font-roboto fw-600 mb30 font-white p24">
                    {card.cardName}
                  </p>
                </Fade>
                {card.heading.map((heading, idx) => {
                  return (
                    <Fade bottom duration={500} delay={(idx + 1) * 80}>
                      <div className="info-list d-flex br10 gap-16 mb24">
                        <div className="icon-img">
                          <img src={card.image[idx].url} />
                        </div>
                        <div className="font-white">
                          <p className="p18 font-roboto fw-500">{heading}</p>
                          <p className="p16 m-0">{card.title[idx]}</p>
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </Col>
            </Container>
          </section>

          <section className="mt100 mb100 detail-section">
            <Container className="Layout-container">
              <Col lg={6}>
                {newfield?.map((infocard, i) => {
                  const {
                    cardName,
                    cardDescription,
                    slug,
                    heading,
                    title,
                    text,
                    content,
                    subtext,
                    picture,
                    links,
                  } = infocard
                  const onDemandWebinar = heading[1]
                    .toLowerCase()
                    .includes("watch")

                  return (
                    <Fade bottom duration={500}>
                      <div className="webinar-card br10 mb50">
                        <div
                          className="d-inline-flex tag align-items-center down-to-up-1 mb30"
                          style={{ background: slug }}
                        >
                          <SVGIcon name="philosophy/star" className="star" />
                          <p className="d-inline font-white text-uppercase fw-bold m-0 word">
                            {cardName}
                          </p>
                        </div>
                        <h3 className="font-roboto fw-600 mb40 font-white p24">
                          {cardDescription}
                        </h3>
                        <div className="fx event-info-wrapper ">
                          <div className="d-flex align-items-center gap-8 mb20 event-info font-white">
                            <SVGIcon name="webinar/calendar" />
                            <p className="fw-500 m-0 p18">{heading[0]}</p>
                          </div>
                          <div
                            className={`d-flex align-items-center gap-8 mb20 event-info font-white ${
                              onDemandWebinar ? "cursor-pointer on-demand" : ""
                            }`}
                            onClick={() => onDemandWebinar && highlightForm()}
                          >
                            {onDemandWebinar ? (
                              <div>
                                <img
                                  src={images[0].url}
                                  className="w-100"
                                  alt="video-icon"
                                />
                              </div>
                            ) : (
                              <SVGIcon name="webinar/clock" />
                            )}

                            <p className="fw-500 m-0 p18">{heading[1]}</p>
                          </div>
                        </div>
                        <div className="speakers mb40 mt20 d-flex flex-wrap">
                          {title?.map((speaker, speakerindex) => {
                            if (picture[speakerindex]?.url)
                              return (
                                <Col lg={4} className="detail">
                                  <img
                                    alt="Img"
                                    src={picture[speakerindex]?.url}
                                  />
                                  <p className="mb-2 p12 fw-500 text-pink mt24">
                                    {speaker}
                                  </p>
                                  <p className="font-roboto font-white fw-500 p16 m-0">
                                    {text[speakerindex]}
                                  </p>
                                  {links[speakerindex] &&
                                  links[speakerindex] !== "-"
                                    ? links[speakerindex]
                                        .split("*")
                                        .map((item) => {
                                          return (
                                            <p className="p14 designation m-0">
                                              {item}
                                            </p>
                                          )
                                        })
                                    : null}
                                </Col>
                              )
                            else return null
                          })}
                        </div>
                        <div>
                          <p className="p16 font-white mb30">
                            {content[0].text}
                          </p>
                          <ul className="list-points">
                            {subtext?.map((listpoint, listindex) => {
                              return (
                                <li>
                                  <p>{listpoint}</p>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </Col>
            </Container>
          </section>
        </div>

        {screenWidth <= 992 ? (
          <ScrollElement name="form">
            <section className="mb100 d-mob">
              <Container className="Layout-container">
                <FormWrapper />
              </Container>
            </section>
          </ScrollElement>
        ) : null}

        <FooterLP items={links[0]} />
      </Frame>
    </div>
  )
}

export default ProfitPlaybook

export function ProfitPlaybookPageQuery() {
  const returneddata = useStaticQuery(graphql`
    query theProfitPlaybook {
      SuperOps {
        pageComponents(where: { pageName: "TheProfitPlaybook" }) {
          seo {
            title
            description
            image {
              url
            }
          }
          hero {
            heading {
              text
            }
            content {
              text
            }
            heroImage {
              url
            }
            primaryButtonText
          }
          card {
            cardName
            heading
            title
            image {
              url
            }
          }
          newfield {
            ... on SuperOps_Card {
              cardName
              cardDescription
              slug
              heading
              title
              text
              content {
                text
              }
              subtext
              picture {
                url
              }
              links
            }
          }
          images {
            url
          }
        }
        links(where: { section: "Footer Security" }) {
          linkName
          slug
        }
      }
    }
  `)

  return returneddata.SuperOps
}
